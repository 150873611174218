<template>
  <b-card>
    <div>
      <div>
        <!-- <strong>items: </strong>{{ items }}
        <div
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
        >
          <strong>Answer{{ index }}(name): </strong>{{ items[index].name }}
        </div>
        Categories: {{ respDataCateg }} -->
        <validation-observer ref="formData">
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-row>
              <!-- Categories -->
              <b-col md="2">
                <b-form-group
                  :label="$t('Categories')"
                  label-for="categories"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="categories"
                    rules="required"
                  >
                    <v-select
                      v-model="categories"
                      :state="errors.length > 0 ? false:null"
                      :options="respDataCateg"
                      :reduce="(respDataCateg) => respDataCateg._id"
                      label="title"
                      :placeholder="$t('PleaseSelect')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Title -->
              <b-col
                md="4"
              >
                <b-form-group
                  :label="$t('Title')"
                  label-for="title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Title')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Content อธิบายคำถาม -->
              <b-col
                md="4"
              >
                <b-form-group
                  :label="$t('Content')"
                  label-for="content"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="content"
                    rules="required"
                  >
                    <b-form-input
                      id="content"
                      v-model="content"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Content')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Row Loop เพิ่มคำตอบเรื่อย ๆ -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <!-- Answer.name -->
              <b-col md="2">
                <b-form-group
                  :label="$t('Answer')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="item.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Answer')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Answer.accessories -->
              <b-col md="5">
                <b-form-group
                  :label="$t('Accessories')"
                  label-for="accessories"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="accessories"
                    rules=""
                  >
                    <v-select
                      v-model="item.accessories"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      :options="respDataCarAcc"
                      :reduce="(respDataCarAcc) => respDataCarAcc._id"
                      label="name"
                      :placeholder="$t('PleaseSelect')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('Delete') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="repeateAgain()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>{{ $t('addAnswer') }}</span>
        </b-button>
      </div>
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="validationForm()"
        >
          {{ dataId !== null ? $t('Edit') : $t('SUBMIT') }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '../../../store/root/formSurvey'

const STORE_MODULE_NAME = 'formSurvey'

export default {
  components: {
    BCard,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        // id: 1,
      }],
      dataId: null,
      categories: '',
      title: '',
      content: '',
      position: 0,
      positionData: [
        { value: 0, text: 'First' },
        { value: 1, text: 'Second' },
        { value: 2, text: 'Third' },
      ],
      // nextTodoId: 2,
      required,
    }
  },
  computed: {
    // CarAcc
    respDataCarAcc() {
      return store.state.formSurvey.respDataCarAcc.data
    },
    // formSurvey
    // respDataForm() {
    //   return store.state.formSurvey.respDataForm.data
    // },
    // Categories
    respDataCateg() {
      return store.state.formSurvey.respDataCateg.data
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: 1,
      pageSize: 10,
    }
    // getCarAcc
    store
      .dispatch(`${STORE_MODULE_NAME}/getAccessories`)
      .then(result => {
        console.log('fetchCarAcc Success : ', result)
        this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'เรียกข้อมูลสำเร็จ')
      }).catch(error => {
        console.log('fetchCarAcc Error : ', error)
        this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เรียกข้อมูลไม่สำเร็จ')
      })

    // Categories
    store
      .dispatch(`${STORE_MODULE_NAME}/getCategoriesForAdd`, obj)
      .then(result => {
        console.log('fetch Success : ', result)
        this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'เรียกข้อมูลสำเร็จ')
      }).catch(error => {
        console.log('fetchUsers Error : ', error)
        this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เรียกข้อมูลไม่สำเร็จ')
      })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    initValues() {
      this.dataId = null
      this.categories = ''
      this.title = ''
      this.content = ''
      this.position = ''
      this.items = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            categoriesId: this.categories,
            title: this.title,
            content: this.content,
            position: this.position,
            // answer: { name: this.name, accessories: this.CarAcc },
            answer: this.items,
          }
          console.log(obj)
          if (this.dataId !== null) {
            store
              .dispatch(`${STORE_MODULE_NAME}/update`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'แก้ไขข้อมูลสำเร็จ')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'แก้ไขข้อมูลไม่สำเร็จ')
              })
          } else {
            delete obj._id
            store
              .dispatch(`${STORE_MODULE_NAME}/addFormSurvey`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.$router.go(-1)
                this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'เพิ่มข้อมูลสำเร็จ')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เพิ่มข้อมูลไม่สำเร็จ')
              })
          }
        }
      })
    },
    repeateAgain() {
      this.items.push({
        // id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          // icon: 'InfoIcon',
          // text: 'I do not think that word means what you think it means.',
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
</style>
