var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('div',[_c('validation-observer',{ref:"formData"},[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Categories'),"label-for":"categories"}},[_c('validation-provider',{attrs:{"name":"categories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.respDataCateg,"reduce":function (respDataCateg) { return respDataCateg._id; },"label":"title","placeholder":_vm.$t('PleaseSelect')},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Title'),"label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Title')},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Content'),"label-for":"content"}},[_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"content","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Content')},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Answer'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Answer')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Accessories'),"label-for":"accessories"}},[_c('validation-provider',{attrs:{"name":"accessories","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"multiple":"","options":_vm.respDataCarAcc,"reduce":function (respDataCarAcc) { return respDataCarAcc._id; },"label":"name","placeholder":_vm.$t('PleaseSelect')},model:{value:(item.accessories),callback:function ($$v) {_vm.$set(item, "accessories", $$v)},expression:"item.accessories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)})],2)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.repeateAgain()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('addAnswer')))])],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" "+_vm._s(_vm.dataId !== null ? _vm.$t('Edit') : _vm.$t('SUBMIT'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }